import React, { useEffect } from 'react';
import '../../styles/home.css';
import homeLogo from '../../assets/home-logo.svg';
import Course from './Course';
import Aos from 'aos';
import "aos/dist/aos.css";

export default function Home(){
  useEffect(()=>{
    localStorage.setItem('lastPage','home');
    Aos.init({duration: 1000});
  },[])
  return(
    <div className="home">
      <div className='home-logo'>
        <img onClick={()=>{window.location.href='/'}} className='logo' src={homeLogo} alt='home logo' />
      </div>
      <div data-aos="fade-right" className='login-notice'>
        <div className='login-text'>Already Have An Account?</div>
        <button onClick={()=>{handleLogInPress()}} className='login-button' type='button'>Log In!</button>
      </div>
      <div className='enroll'>
        <Course name='4 Week Transformation Programs for Men' desc='Programs in this category feature a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.' id={'00000'} />
        <Course name='4 Week Transformation Programs for Women' desc='Programs in this category feature a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.' id={'00001'} />
      </div>
      <div data-aos="fade-right" className='about-me'>
        <div className='about-title'>About Me</div>
        <div className='about-text'>
          My name is Luciano LaMorta and I have been training clients for 5 years now! I discovered my passion for fitness about 10 years ago and I am currently enrolled in a doctorate program for physical therapy in order to further my education in the field. I have trained hundreds of clients in my experience and my mission is to embody the truth of <br />
          <em className='red'>“Look Good, Feel Good”</em>.<br /> One goal of mine is to share my passion with others in order for you to pursue your best life possible! Without further ado,<br />
          The <em className='red'>BEST</em> time to start is <em className='red'>NOW!</em>
        </div>
      </div>
      <div data-aos="fade-right" className='testimonials'>
        <div className='testimonial-title'>Testimonials</div>
        <div>
          <div className='testimonial-text'>
            <span className='quote'>"</span>Luciano has <em className='red'>in-depth knowledge</em> on human biology and muscle groups. He doesn't just prescribe the workouts but walks you through how every workout would affect each muscle group. All of his clients walk out of the session more
            <em className='red'> knowledgeable and stronger</em> than they have ever felt. As a recent client of Luciano, I would absolutely recommend him for your next New Years resolution.<span className='quote'>"</span>
          </div>
          <div className='testimonial-credit'>- Nafis</div>
        </div>
        <div>
          <div className='testimonial-text'><span className='quote'>"</span>I have been working out consistently for about 6 months now and i could not be happier with my results. I have <em className='red'>lost 60 lbs</em>&nbsp;in that time frame! The <em className='red'>advice and guidance</em> i have gotten from Luciano is second to none. He has been a great help since i began my fitness journey!<span className='quote'>"</span></div>
          <div className='testimonial-credit'>- Alex</div>
        </div>
        <div>
          <div className='testimonial-text'><span className='quote'>"</span>I'm so lucky to have met Luciano as my personal trainer! Not only is he <em className='red'>knowledgeable and experienced</em>, he is attentive to my needs. He has helped me to get into the best shape in my life! Would highly recommend to friends and family.<span className='quote'>"</span></div>
          <div className='testimonial-credit'>- Mark</div>
        </div>
        <div>
          <div className='testimonial-text'><span className='quote'>"</span>Lucc, I joined the gym 1 month ago because I was getting really really close to 150. <em className='red'>A number I've never been before.</em> Just weighed myself-I'm officially back to <em className='red'>140lbs </em>!<span className='quote'>"</span></div>
          <div className='testimonial-credit'>- Melani</div>
        </div>
      </div>
      <div className='footer'>
        <div data-aos="fade-in" className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
      </div>
    </div>
  )
}
let handleLogInPress = function(){
  if (typeof(localStorage.getItem('userID'))==='string'){
    window.location.href='/user';
  }else{
    window.location.href='/login';
  }
}