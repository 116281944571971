import React from 'react';
import { Link } from 'react-router-dom';
export default function CourseItem({title,desc,courseID,courses}:{title:string,desc:string,courseID:string,courses:string[]}){
  return(
    <div className='course-item'>
      <div className='course-item-title'>{title}</div>
      <div className='course-desc'>{desc}</div>
      {
        getCourseButton(courses,courseID)
      }
    </div>
  )
}

let getCourseButton = function(courses: string[], courseID: string) {
  if(courses[0] === '') {
    return(
      <button>Loading...</button>
    );
  }

  if (courseID === '00000') {
    return(
      <div className='user-course-buttons'>
        {
          courses.includes('00002') ?
          <Link to={`/course/00002`}>
            <button>View Beginner Program</button>
          </Link>
          : <button type='button' onClick={()=>{window.location.href='/checkout/00002'}}>Purchase Beginner Program</button>
        }
        {
          courses.includes('00000') ?
            <Link to={`/course/00000`}>
              <button>View Intermediate Program</button>
            </Link>
            : <button type='button' onClick={()=>{window.location.href='/checkout/00000'}}>Purchase Intermediate Program</button>
        }
        {
          courses.includes('00003') ?
          <Link to={`/course/00003`}>
            <button>View Advanced Program</button>
          </Link>
          : <button type='button' onClick={()=>{window.location.href='/checkout/00003'}}>Purchase Advanced Program</button>
        }
      </div>
    );
  }

  if (courseID === '00001') {
    return(
      <div className='user-course-buttons'>
        {
          courses.includes('00004') ?
          <Link to={`/course/00004`}>
            <button>View Beginner Program</button>
          </Link>
          : <button type='button' onClick={()=>{window.location.href='/checkout/00004'}}>Purchase Beginner Program</button>
        }
        {
          courses.includes('00000') ?
            <Link to={`/course/00001`}>
              <button>View Intermediate Program</button>
            </Link>
            : <button type='button' onClick={()=>{window.location.href='/checkout/00000'}}>Purchase Intermediate Program</button>
        }
        {
          courses.includes('00005') ?
          <Link to={`/course/00005`}>
            <button>View Advanced Program</button>
          </Link>
          : <button type='button' onClick={()=>{window.location.href='/checkout/00005'}}>Purchase Advanced Program</button>
        }
      </div>
    );
  }
}


/*

else if (courses.includes(courseID)){
    return(
      //dynamic route to course
      <div className='user-course-buttons'>
        <Link to={`/course/`+courseID}>
          <button>View Beginner Program</button>
        </Link> 
        <Link to={`/course/`+courseID}>
          <button>View Intermediate Program</button>
        </Link> 
        <Link to={`/course/`+courseID}>
          <button>View Advanced Program</button>
        </Link> 
      </div> 
    )
  } else if(!courses.includes(courseID)){
    return(
      //bring to purchase page for course
      <button type='button' onClick={()=>{window.location.href='/checkout/'+courseID}}>Purchase Program</button>
    )
  }

*/