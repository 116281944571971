import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyACmy6TliQ8vOw7IKetuOs-0Hk3JRCzMY4",
  authDomain: "luccifit-91438.firebaseapp.com",
  projectId: "luccifit-91438",
  storageBucket: "luccifit-91438.appspot.com",
  messagingSenderId: "230629793405",
  appId: "1:230629793405:web:69f5963bfafaca3c15db14",
  measurementId: "G-RFXLQTMB3S"
};

export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);