export default class User{
  courses: string[];
  email:string;
  name: string;
  uid: string;
  constructor(courses:string[],email:string,name:string,uid:string){
    this.courses=courses;
    this.email=email;
    this.name=name;
    this.uid=uid;
  };
}