import React, { useEffect,useState } from 'react';
import homeLogo from '../../assets/home-logo.svg';
import '../../styles/user.css';
import '../../styles/checkout.css'
import instagramLogo from '../../assets/instagram-logo.svg';
import tiktokLogo from '../../assets/tiktok-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';
import mailLogo from '../../assets/mail-logo.svg';
import { useParams } from 'react-router-dom';
import {database} from '../../firebaseConfig';
import { getDocs,query,where , collection} from 'firebase/firestore';

export default function Checkout(){
  const {id}=useParams();
  const [canCheckout,setCanCheckout] = useState(false);
  const [courseInfo, setCourseInfo] = useState({
      name: 'Loading',
      price: '',
      id: id,
  });

  useEffect(()=>{
    if (courseInfo.id!==undefined) getCourseInfo(courseInfo.id,setCourseInfo);
    if (courseInfo.id!==undefined) checkIfCheckout(setCanCheckout,courseInfo.id);
    if (localStorage.getItem('lastPage')!=='checkout'){
      localStorage.setItem('aabbzc','');
    }
    localStorage.setItem('lastPage','checkout');
  },[]);

  useEffect(()=>{
    let i:any = document.querySelector('.checkout-notice');
    if (canCheckout===true){
      i.style.display='none';
    }else{
      i.style.display='block';
    }
  },[canCheckout]);

  return(
      <div className='checkout'>
        <div className='checkout-owns-course'>
          <div className='checkout-owns-course-notice'>It appears you already own this program!</div>
          <button className='checkout-owns-course-button' onClick={()=>{window.location.href='/course/'+courseInfo.id}}>View Program</button>
        </div>
        <div className='checkout-logo-container'>
          <img className='user-logo' onClick={()=>{window.location.href='/'}} src={homeLogo} alt='home logo' />
        </div>
        <div className='checkout-cart'>
          <div className='checkout-cart-name'>{courseInfo.name}</div>
          <div className='checkout-cart-price'>{'$'+courseInfo.price}</div>
        </div>
        <form className='checkout-form'>
          {getCourseDesc(courseInfo.id)}
          <div className='checkout-form-buttons'>
            <div className='checkout-notice'>To complete checkout please log in to an existing account or sign up.</div>
            {getButtons(canCheckout,courseInfo)}
          </div>
        </form>
        <div className='footer'>
          <div className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
          <div className='social-media-container'>
            <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
            <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
            <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
            <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
          </div>
        </div>
      </div>
    )
};
let getCourseDesc=function(id:any){
  let desc:string = '';
  if (id==='00000' || id==='00002' || id==='00003'){
    desc='This program features a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.';
  } else if (id==='00001'|| id==='00004' || id==='00005'){
    desc='This program features a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.';
  }
  return(<div className='course-desc'>{desc}</div>);
};

let checkIfCheckout= async function(setCanCheckout:any,courseID:string){
  //checks to see if user is logged in
  if (localStorage.getItem('userID')) setCanCheckout(true);
  //checks to see if the user doesn't own the course
  const q = query(collection(database,'users'),where('uid','==',localStorage.getItem('userID')));
  try{
    await getDocs(q).then((r)=>{
      r.forEach((i)=>{
        const courses:string[] = i.data().courses;
        if (courses.includes(courseID)) alreadyOwnsCourse(setCanCheckout);
      })
    })
  }catch(e){
    console.log(e);
  }
};

let getCourseInfo = async function(courseID:string, setCourseInfo: any){
  const q = query(collection(database,'courses'),where('id','==',courseID));
  await getDocs(q).then((r)=>{
    r.forEach((doc)=>{
      setCourseInfo({
        name: doc.data().name,
        price: doc.data().price,
        id: courseID,
      })
    })
  })  
};

let getButtons = function(canCheckout:boolean,courseInfo:any){
  if (canCheckout===false){
    return(
      <>
        <button type='button' onClick={()=>{window.location.href='/login'}}>Log In</button>
        <button type='button' onClick={()=>{window.location.href='/register'}}>Register</button>
      </>      
    )
  }else{
    return(
      <>
        <button onClick={()=>{handleCheckout(courseInfo);}} type='button' className='pay-button'>Pay Now!</button>
      </>
    )
  }
};

let handleCheckout = async function(courseInfo:any){
  //get checkout button
  const payButton:any = document.querySelector('.pay-button');
  //lockout the pay button 
  payButton.textContent='Processing...';
  payButton.disabled=true;
  localStorage.setItem('aabbzc',courseInfo.id);
  switch (courseInfo.id){
    case '00000':
      window.location.href='https://buy.stripe.com/28o02UbYjcn14XmdQQ';
      break;
    case '00001':
      window.location.href='https://buy.stripe.com/3cs6ri8M7cn1dtS001';
      break;
    case '00002':
      window.location.href='https://buy.stripe.com/28o02UbYjcn14XmdQQ';
      break;
    case '00003':
      window.location.href='https://buy.stripe.com/28o02UbYjcn14XmdQQ';
      break;
    case '00004':
      window.location.href='https://buy.stripe.com/3cs6ri8M7cn1dtS001';
      break;
    case '00005':
      window.location.href='https://buy.stripe.com/3cs6ri8M7cn1dtS001';
      break;
  };
};

let alreadyOwnsCourse = function(setCanCheckout:any){
  setCanCheckout(false);
  const checkoutNoticeDiv:any = document.querySelector('.checkout-owns-course');
  checkoutNoticeDiv.style.display='flex';
};
