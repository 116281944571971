import React, { useEffect,useState } from 'react';
import '../styles/checkoutComplete.css';
import {database} from '../firebaseConfig';
import { getDocs,query,where , collection, updateDoc} from 'firebase/firestore';
export default function CheckoutComplete(){
  const [courseID,setCourseID] = useState('');
  useEffect(()=>{
    let course:any = localStorage.getItem('aabbzc');
    if (course){
      setCourseID(course)
      localStorage.setItem('lastPage','checkoutComplete');
      if (course.length===5){
        updateUser(course);
      }
    }
    
  },[])
 
  return(
    <div className='checkout-complete'>
      <div className='checkout-complete-title'>Thank you for your order! You now have access to the program.</div>
      <button className='checkout-complete-view-course' onClick={()=>{window.location.href='/course/'+courseID}}>View Course</button>
    </div>
  )
}
let updateUser = async function (courseID:string){
  const UID: any= localStorage.getItem('userID');
  const q = query(collection(database,'users'),where('uid','==', UID));
  await getDocs(q).then((snapshot)=>{
    snapshot.forEach((r)=>{
      const updatedUser = {
        courses: r.data().courses.concat(courseID),
        email: r.data().email,
        uid: r.data().uid,
      };
      try{
        updateDoc(r.ref,updatedUser).then(()=>{
          localStorage.setItem('aabbzc','');
        })
      }
      catch(e){
        console.log('There was an error updating the user, ',e);
      }
    })
  })
};