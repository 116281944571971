import React, { useEffect } from 'react';
import homeLogo from '../../assets/home-logo.svg';
import '../../styles/user.css';
import {collection, where, getDocs, query} from 'firebase/firestore';
import { database, app } from '../../firebaseConfig';
import User from '../../classes/User';
import CourseItem from '../content/CourseItem';
import {getAuth, signOut} from 'firebase/auth'
import instagramLogo from '../../assets/instagram-logo.svg';
import tiktokLogo from '../../assets/tiktok-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';
import mailLogo from '../../assets/mail-logo.svg';
export default function UserPanel({currentUser,setCurrentUser}:any){
  //get the current user from firebase and update state with it
  useEffect(()=>{
    getUser(setCurrentUser);
    localStorage.setItem('lastPage','userpanel');
  },[]);
  return(
      <>
        <div className='userpanel'>
          <div className='user-logo-container'>
            <img className='user-logo' onClick={()=>{window.location.href='/'}} src={homeLogo} alt='home logo' />
          </div>
          <div className='user-title'>Programs</div>
          <div className='user-course-container'>
            <CourseItem title='4 Week Transformation Programs for Men' desc='Programs in this category feature a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.' courseID='00000' courses={currentUser.courses} />
            <CourseItem title='4 Week Transformation Programs for Women' desc='Programs in this category feature a 4 week workout plan that is 3-4 days per week. Video tutorials are included for each exercise.' courseID='00001' courses={currentUser.courses} />
          </div>
          <div className='user-footer'>
            <button onClick={()=>{handleLogout()}} className='sign-out-button'>Log Out</button>
          </div>
          <div className='footer'>
            <div className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
            <div className='social-media-container'>
              <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
              <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
              <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
              <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
            </div>
          </div>
        </div>
      </>
    )
};

let getUser = async function(setCurrentUser:any){
  //load userid from local storage
  let login: any = localStorage.getItem('userID');
  // query the database for the uid
  let snapshot: any;
  const userRef = collection(database, 'users');
  const userQuery = query(userRef,where('uid','==',login));
  try{
    snapshot = await getDocs(userQuery);
  }catch(e){
    console.log('Error retrieving user account. Please try again.');
  }
  snapshot.forEach((doc:any)=>{
    // create a new user class locally with the match from firestore and set state with new data
    setCurrentUser(new User(doc.data().courses,doc.data().email,doc.data().name,doc.data().uid));
  })
};
let handleLogout = function(){
  signOut(getAuth(app));
  localStorage.clear();
  window.location.href='/login'
}