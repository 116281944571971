import React, { useEffect, useState } from 'react';
import homeLogo from '../../assets/home-logo.svg';
import closeImg from '../../assets/close.svg';
import {getAuth,createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {app,database} from '../../firebaseConfig';
import '../../styles/register.css'
import { handleLogin } from './Login';
import instagramLogo from '../../assets/instagram-logo.svg';
import tiktokLogo from '../../assets/tiktok-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';
import mailLogo from '../../assets/mail-logo.svg';
import { addDoc , collection} from 'firebase/firestore';
const auth = getAuth(app);

export default function Register(){
  useEffect(()=>{
    localStorage.setItem('lastPage','register');
  },[])
  // set input states
  const [emailInput,setEmailInput] = useState('');
  const [passwordInput,setPasswordInput] = useState('');
  const [passwordConfInput,setPasswordConfInput] = useState('');
  const [errorMessages,setErrorMessages] = useState();
  return(
    <div className='reg-comp'>
      <div className='home-logo-register'>
        <img className='logo' src={homeLogo} alt='home logo' />
      </div>
      <div className='register'>
        <form className='register-form'>
          <img className='close-img' onClick={()=>{window.location.href = '/';}} src={closeImg} /> 
          <div className='email-register'>
            <label>Email</label>
            <input value={emailInput} onChange={(e)=>{setEmailInput(e.target.value)}} type='email' />
          </div>
          <div className='password-register'>
            <label>Password</label>
            <input value={passwordInput} onChange={(e)=>{setPasswordInput(e.target.value)}} type='password' />
          </div>
          <div className='password-conf-register'>
            <label>Confirm Password</label>
            <input value={passwordConfInput} onChange={(e)=>{setPasswordConfInput(e.target.value)}} type='password' />
          </div>
          <div className='register-messages'>{errorMessages}</div>
          <button id='signup-button' onClick={()=>{handleRegister(emailInput,passwordInput,passwordConfInput,setErrorMessages)}} className='course-button' type='button'>Complete Sign Up</button>
        </form>
      </div>
      <div className='footer'>
        <div className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
        <div className='social-media-container'>
          <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
          <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
          <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
          <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
        </div>
      </div>
    </div>
  )
}

let validateForm = function(emailInput:string,passwordInput:string,passwordConfInput:string,setErrorMessages:any){
  let isFormValid=true;
  let tempMessage:string='';
  //check email for valid email
  let emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(emailInput)){
    tempMessage=tempMessage+'Please enter a valid email.';
    isFormValid=false;
  }
  //check to see if passwords match
  if (passwordInput!==passwordConfInput){
    tempMessage=tempMessage+'Passwords do not match.';
    isFormValid=false;
  }
  // return boolean on if form is valid or not
  if (isFormValid===false){
    setErrorMessages(tempMessage);
    return isFormValid;
  }
  else{
    setErrorMessages();
    return isFormValid;
  }
};

let handleRegister = async function(emailInput:string,passwordInput:string,passwordConfInput:string,setErrorMessages:any){
  if (!validateForm(emailInput,passwordInput,passwordConfInput,setErrorMessages)) return 0;
  try{
    // create a new user
    let login = await createUserWithEmailAndPassword(auth,emailInput,passwordInput);
    // send email verification
    await sendEmailVerification(login.user);
    //create doc for user
    await addDoc(collection(database,'users'),{
      courses:[],
      email: emailInput,
      uid: login.user.uid,
    });
    // call handle login from login.tsx to complete sign in
    await handleLogin(emailInput,passwordInput,setErrorMessages);
  }catch(e){
    setErrorMessages('A technical error has occured please try again!');
  }
};