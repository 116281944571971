import React, {useState,useEffect} from 'react';
import homeLogo from '../../assets/home-logo.svg';
import '../../styles/login.css';
import closeImg from '../../assets/close.svg'
import {getAuth,sendPasswordResetEmail,signInWithEmailAndPassword} from 'firebase/auth';
import {app} from '../../firebaseConfig';
import instagramLogo from '../../assets/instagram-logo.svg';
import tiktokLogo from '../../assets/tiktok-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';
import mailLogo from '../../assets/mail-logo.svg';
const auth = getAuth(app);

export default function Login(){
  useEffect(()=>{
    localStorage.setItem('lastPage','login');
  },[])
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  return(
    <div className='login-comp'>
      <div className='home-logo-login'>
        <img className='logo' src={homeLogo} alt='home logo' />
      </div>
      <div className='login'>
        <form className='login-form'>
          <img className='close-img' onClick={()=>{window.location.href = '/';}} src={closeImg} /> 
          <div className='email-login'>
            <label>Email</label>
            <input value={emailInput} onChange={(e)=>{setEmailInput(e.target.value)}} type='email' />
          </div>
          <div className='password-login'>
            <label>Password</label>
            <input value={passwordInput} onChange={(e)=>{setPasswordInput(e.target.value)}} type='password' />
          </div>
          <button id='login-button' onClick={()=>{handleLogin(emailInput,passwordInput,setErrorMessage)}} className='course-button' type='button'>Log In</button>
          <button id='register-button' onClick={()=>{window.location.href = '/register';}} className='course-button' type='button'>Sign Up</button>
          <div className='login-messages'>{errorMessage}</div>
          <button onClick={()=>{handleForgotPassword(emailInput,setErrorMessage)}} className='forgot-password' type='button'>Forgot Password</button>
        </form>
      </div>
      <div className='footer'>
        <div className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
        <div className='social-media-container'>
          <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
          <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
          <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
          <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
        </div>
      </div>
    </div>
  )
};

let handleForgotPassword = async function(emailInput:string, setErrorMessage:any){
  try{
    await sendPasswordResetEmail(auth,emailInput);
    setErrorMessage('Password reset email sent.');
  }catch(e){
    setErrorMessage('Please enter a valid email.');
  }
};

export async function handleLogin(emailInput:string, passwordInput:string,setErrorMessage:any){
  let login:any;
  try{
    // sign in with email and password input
    login = await signInWithEmailAndPassword(auth,emailInput,passwordInput);
    setErrorMessage();
  }catch(e){
    setErrorMessage('Could not find an account with the entered email and password.');
  }
  //save data to local storage
  localStorage.setItem('userID',login.user.uid);
  // redirect user to login panel
  window.location.href='/user';
};