import React, {useEffect} from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
export default function Course({name, desc, id}:{name:string, desc:string, id:string}){
  let beginner: string;
  let advanced: string;
  if (id==='00000'){
    beginner = '00002'; 
    advanced = '00003';
  }
  if (id==='00001'){
    beginner = '00004'; 
    advanced = '00005';
  }
  useEffect(() => {
    // Remove fadein class from course buttons when component is first rendered
    const courseButtons = document.querySelectorAll(`.course-button.course-${id}`);
    courseButtons.forEach((i:any)=>{
      i.classList.remove('fadein');
    })
    Aos.init({duration: 1000});
  }, [id]);

  return(
    <div data-aos="fade-right" className={`course course-${id}`}>
      <div className='course-name'>{name}</div>
      <div className='course-desc'>{desc}</div>
      <div className='enroll-container'>
        <button className={`course-button enroll-button-${id}`} onClick={()=>{handleEnroll(id)}}>Enroll</button> {/* Add unique ID */}
        <div className={`course-buttons-container course-buttons-container-${id}`}>
          <button className={`course-button course-beginner course-${id}`} onClick={()=>{window.location.href = '/checkout/'+beginner}}>Enroll Beginner</button>
          <button className={`course-button course-intermediate course-${id}`} onClick={()=>{window.location.href = '/checkout/'+id}}>Enroll Intermediate</button>
          <button className={`course-button course-advanced course-${id}`} onClick={()=>{window.location.href = '/checkout/'+advanced}}>Enroll Advanced</button>
        </div>
      </div>
    </div>
  )
}

let handleEnroll = function(id: string){ // Add additional parameter
  console.log(id);
  const enrollButton:any = document.querySelector(`.enroll-button-${id}`); // Select unique enroll button
  const allCourseButtons:any = document.querySelectorAll(`.course-button.course-${id}`);
  const courseButtonsContainer:any = document.querySelector(`.course-buttons-container.course-buttons-container-${id}`);
  enrollButton.classList.add('fadeout');
  enrollButton.style.display = 'none';
  if (courseButtonsContainer) {
    courseButtonsContainer.style.display = 'flex';
  }
  allCourseButtons.forEach((i:any)=>{
    i.classList.add('fadein');
  })
};
