import React, {useState, useEffect} from 'react';
import courseContentLogo from '../../assets/home-logo.svg';
import '../../styles/course.css';
import instagramLogo from '../../assets/instagram-logo.svg';
import tiktokLogo from '../../assets/tiktok-logo.svg';
import youtubeLogo from '../../assets/youtube-logo.svg';
import mailLogo from '../../assets/mail-logo.svg';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDocs,collection,where,query } from 'firebase/firestore';
import { database,app } from '../../firebaseConfig';

export default function CourseContent({currentUser}:any){
  const [hasPerm,setHasPerm] = useState(true);
  const [currentVideo,setCurrentVideo] = useState('');
  const {id} = useParams();
  useEffect(()=>{
    //verify user owns content when opening a course item
    ownsCourse(id,setHasPerm)
  })
  useEffect(()=>{
    localStorage.setItem('lastPage','courseContent');
  },[])
    if (hasPerm){
    return(
      <>
        <div className='current-course-video'>
          <video key={Math.random()} className='course-video' controls>
            <source src={currentVideo} type='video/mp4' />
          </video>
          <button className='close-video-button' onClick={()=>{toggleCourseVideo()}}>Close Video</button>
        </div>
        <div className='course-content'>
          <img className='course-content-logo' src={courseContentLogo} onClick={()=>{window.location.href='/'}} />
          {getCourseContent(id,setCurrentVideo)}
          <div className='footer'>
            <div className='copyright'>© 2023 Lucci Fit LLC. All rights reserved.</div>
            <div className='social-media-container'>
              <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
              <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
              <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
              <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
            </div>
          </div>
        </div>
      </>
    )
  }else{
    return(
      <div className='notice-container'>
        <div className='notice-title'>It appears you do not own this program. If you already own it, please log in to view this content.</div>
        <button onClick={()=>{window.location.href='/login'}}>Login</button>
      </div>
    )
  }
};

let getCourseContent = function(courseID:any,setCurrentVideo:any){
  if (courseID==='00000'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FMiddle%20Cable%20Chest%20Fly.mp4?alt=media&token=4fefae83-e279-4e4d-a870-c619b2ff67e6')}}>{`3)`} Middle cable flys 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLow%20to%20High%20Cable%20Fly.mp4?alt=media&token=afb6d50b-4c21-4297-9a88-ba31435e79c9')}}>{`4)`} Low to high cable flys 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`5)`} DB lateral raise 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Lateral%20Raise.mp4?alt=media&token=c05d1b01-0506-491d-8fd4-7d6d5ad87a75')}}>{`6)`} Cable lateral raise 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7)`} Rope extensions 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8)`} EZ cable extensions 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FWide%20Grip%20Lat%20Pulldown.mp4?alt=media&token=d7689d36-15fc-4bc3-8e89-36590f66f687')}}>{`1)`} Lat pulldown wide grip 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3)`} Cable row machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDante's%20Rows.mp4?alt=media&token=ae22ccc2-08d7-49c6-aabe-16df095556f7")}}>{`4)`} Dante's rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`6)`} DB curls 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`7)`} DB hammer curls 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 3 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`2)`} Leg extension machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`3)`} Barbell hip thrust / Hip thrust machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 3 x 6-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFrog%20Kicks.mp4?alt=media&token=3a682127-cde4-46cd-9cac-e8588bb92c5d')}}>{`2)`} Frog kicks 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`3)`} Russian twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`4)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2)`} Incline press machine 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`3)`} Chest press machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Fly%20Machine.mp4?alt=media&token=5f6803c0-2192-4d18-bf3b-a80ab6f6c8a1')}}>{`4)`} Chest fly machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`5)`} Lateral raise machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7)`} Rope extensions 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`8)`} DB skullcrushers 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`2)`} Cable row machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94')}}>{`3)`} Kneeling lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`4)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`6)`} EZ inside grip curls 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=cee86cfc-2111-45c5-8f95-39885dfe9f05')}}>{`7)`} EZ outside grip curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`2)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FSplit%20Squats.mp4?alt=media&token=0278f009-c195-4c9f-8465-8468956701f6')}}>{`3)`} Split squats 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curl machine 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`1)`} Russian twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`2)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`3)`} Toe touches 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`4)`} Bicycle crunches 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`1)`} Chest press machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2a)`} Incline press machine 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLow%20to%20High%20Cable%20Fly.mp4?alt=media&token=afb6d50b-4c21-4297-9a88-ba31435e79c9')}}>{`3)`} Low to high cable flys 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4)`} High to low cable flys 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FShoulder%20Press%20Machine.mp4?alt=media&token=c10a6aa6-eda6-4d57-bd73-7e5617bbce60')}}>{`5)`} Shoulder press machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7)`} Rope extensions 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8a)`} EZ cable extensions 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`8b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`1)`} Cable row machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`2a)`} Lat pulldown 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`3)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDante's%20Rows.mp4?alt=media&token=ae22ccc2-08d7-49c6-aabe-16df095556f7")}}>{`4)`} Dante's rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`6)`} DB hammer curls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`7)`} DB curls 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FSplit%20Squats.mp4?alt=media&token=0278f009-c195-4c9f-8465-8468956701f6')}}>{`2)`} Split squats 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`3a)`} Leg extension 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`3a)`} Incline press machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4)`} High to low cable flys 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Lateral%20Raise.mp4?alt=media&token=c05d1b01-0506-491d-8fd4-7d6d5ad87a75')}}>{`5)`} Cable lateral raise 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`6)`} Lateral raise machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`7)`} DB skull crushers 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8a)`} EZ extensions 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`8b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3a)`} Cable row machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94')}}>{`4)`} Kneeling lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`6)`} DB curls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`7)`} EZ inner grip curls 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`2)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`3)`} Barbell hip thrust / Hip thrust machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5a)`} Seated hamstring curls 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`2)`} Knee to elbow crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`4)`} Russian twists 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  }else if (courseID==='00001'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`2)`} DB lateral raise 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`3)`} Cable lateral raise 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`4)`} Rope extensions 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`5)`} EZ cable extensions 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`3)`} Cable row machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDante's%20Rows.mp4?alt=media&token=21edc9e4-5559-43f9-adf3-3afd2af9caf8")}}>{`4)`} Dante's rows 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6)`} DB curls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Hammer%20Curls.mp4?alt=media&token=79ec19c7-1967-49b6-9d8a-c900c7fed7c5')}}>{`7)`} DB hammer curls 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`2)`} Barbell hip thrust / Hip thrust machine 3 x 10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`3)`} DB RDLs 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`4)`} Seated hamstring curl machine 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFrog%20Kicks.mp4?alt=media&token=905cef9b-d581-4b81-a0b3-45342765e57d')}}>{`2)`} Frog kicks 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`3)`} Russian Twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`4)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raises 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`3)`} Lateral raise machine 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`4)`} DB lateral raises 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`5)`} Rope extensions 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6)`} EZ extensions 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`7)`} DB skullcrushers 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`2)`} Cable row machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`3)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`4)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=ebad7e30-03e7-40c5-8b83-1079602b6cc5')}}>{`6)`} EZ inside grip curls 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`7)`} EZ outside grip curls 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`2)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`3)`} Split squats 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5)`} Seated hamstring curl machine 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`1)`} Russian twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`2)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`2a)`} Shoulder press machine 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`3)`} DB lateral raises 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`4)`} DB skullcrushers 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`5a)`} EZ cable extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`6)`} Rope extensions 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`1)`} Cable row machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`2a)`} Lat pulldown 2 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`3)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Hammer%20Curls.mp4?alt=media&token=79ec19c7-1967-49b6-9d8a-c900c7fed7c5')}}>{`6)`} DB hammer curls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`7)`} DB curls 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`2)`} Split squats 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3)`} Barbell hip thrust / Hip thrust machine 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5a)`} Seated hamstring curl 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raise 4 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`3)`} Cable lateral raise 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`4)`} Lateral raise machine 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`5)`} Rope extensions 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6a)`} EZ extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`6b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`7)`} DB skullcrushers 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`3a)`} Cable row machine 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 4 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6)`} DB curls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`7)`} EZ outer grip curls 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`2)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3)`} Barbell hip thrust / Hip thrust machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5a)`} Seated hamstring curls 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`2)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`4)`} Russian twists 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  }else if (courseID==='00002'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FMiddle%20Cable%20Chest%20Fly.mp4?alt=media&token=4fefae83-e279-4e4d-a870-c619b2ff67e6')}}>{`3)`} Middle cable flys 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`4)`} DB lateral raise 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Front%20Press.mp4?alt=media&token=c0e51c5a-57d9-45e6-8168-8f6ff6ad925b')}}>{`5)`} DB front raise 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`6)`} Rope extensions 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3)`} Cable row machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`4)`} DB curls 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`5)`} DB hammer curls 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 3 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`2)`} Leg extension machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`3)`} DB RDLs 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`4)`} Seated hamstring curl machine 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFrog%20Kicks.mp4?alt=media&token=3a682127-cde4-46cd-9cac-e8588bb92c5d')}}>{`2)`} Frog kicks 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`3)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2)`} Incline press machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`3)`} Chest press machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Fly%20Machine.mp4?alt=media&token=5f6803c0-2192-4d18-bf3b-a80ab6f6c8a1')}}>{`4)`} Chest fly machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`5)`} Lateral raise machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7)`} Rope extensions 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`8)`} DB skullcrushers 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`2)`} Cable row machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`3)`} DB rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`4)`} Face pulls 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`5)`} EZ inside grip curls 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=cee86cfc-2111-45c5-8f95-39885dfe9f05')}}>{`6)`} EZ outside grip curls 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`2)`} Leg press 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FSplit%20Squats.mp4?alt=media&token=0278f009-c195-4c9f-8465-8468956701f6')}}>{`3)`} Split squats 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curl machine 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`1)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`2)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`3)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`1)`} Chest press machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2)`} Incline press machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLow%20to%20High%20Cable%20Fly.mp4?alt=media&token=afb6d50b-4c21-4297-9a88-ba31435e79c9')}}>{`3)`} Low to high cable flys 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4)`} High to low cable flys 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FShoulder%20Press%20Machine.mp4?alt=media&token=c10a6aa6-eda6-4d57-bd73-7e5617bbce60')}}>{`5)`} Shoulder press machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7)`} Rope extensions 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8)`} EZ cable extensions 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`1)`} Cable row machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`2)`} Lat pulldown 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`3)`} DB rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94")}}>{`4)`} Kneeling lat pulldown 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`6)`} DB hammer curls 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`7)`} DB curls 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 3 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`2)`} Leg extension 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`3)`} DB RDLs 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`4)`} Barbell hip thrust / hip thrust machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curls 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`2)`} Russian twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`3)`} Toe touches 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`4)`} Bicycle crunches 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`3)`} Incline press machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4)`} High to low cable flys 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Lateral%20Raise.mp4?alt=media&token=c05d1b01-0506-491d-8fd4-7d6d5ad87a75')}}>{`5)`} Cable lateral raise 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`6)`} Lateral raise machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`7)`} DB skull crushers 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8)`} EZ extensions 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3)`} Cable row machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94')}}>{`4)`} Kneeling lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`6)`} DB curls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`7)`} EZ inner grip curls 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`2)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`3)`} Barbell hip thrust / Hip thrust machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`4)`} Knee to elbow crunches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  }else if (courseID==='00003'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FMiddle%20Cable%20Chest%20Fly.mp4?alt=media&token=4fefae83-e279-4e4d-a870-c619b2ff67e6')}}>{`3a)`} Middle cable flys 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLow%20to%20High%20Cable%20Fly.mp4?alt=media&token=afb6d50b-4c21-4297-9a88-ba31435e79c9')}}>{`4)`} Low to high cable flys 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`5)`} DB lateral raise 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Lateral%20Raise.mp4?alt=media&token=c05d1b01-0506-491d-8fd4-7d6d5ad87a75')}}>{`6)`} Cable lateral raise 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`7)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7a)`} Rope extensions 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`7b)`} EZ cable extensions 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3a)`} Cable row machine 2 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDante's%20Rows.mp4?alt=media&token=ae22ccc2-08d7-49c6-aabe-16df095556f7")}}>{`4)`} Dante's rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5a)`} Face pulls 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`6)`} DB curls 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`7)`} DB hammer curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`2)`} Leg extension machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`3a)`} Barbell hip thrust / Hip thrust machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5a)`} Seated hamstring curl machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFrog%20Kicks.mp4?alt=media&token=3a682127-cde4-46cd-9cac-e8588bb92c5d')}}>{`2)`} Frog kicks 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`3)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`4)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2a)`} Incline press machine 2 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`3)`} Chest press machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Fly%20Machine.mp4?alt=media&token=5f6803c0-2192-4d18-bf3b-a80ab6f6c8a1')}}>{`4a)`} Chest fly machine 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`4b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`5)`} Lateral raise machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7a)`} Rope extensions 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`7b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`8)`} DB skullcrushers 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`2a)`} Cable row machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94')}}>{`3)`} Kneeling lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`4)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5a)`} Face pulls 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`6)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`6a)`} EZ inside grip curls 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=cee86cfc-2111-45c5-8f95-39885dfe9f05')}}>{`6b)`} EZ outside grip curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`2)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FSplit%20Squats.mp4?alt=media&token=0278f009-c195-4c9f-8465-8468956701f6')}}>{`3)`} Split squats 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curl machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
            <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`1)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`2)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`3)`} Toe touches</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`4)`} Bicycle crunches</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FChest%20Press%20Machine.mp4?alt=media&token=fa9d4ed3-76fa-48f7-83c8-2749834a9404')}}>{`1)`} Chest press machine 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`2a)`} Incline press machine 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLow%20to%20High%20Cable%20Fly.mp4?alt=media&token=afb6d50b-4c21-4297-9a88-ba31435e79c9')}}>{`3)`} Low to high cable flys 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4a)`} High to low cable flys 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`4b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FShoulder%20Press%20Machine.mp4?alt=media&token=c10a6aa6-eda6-4d57-bd73-7e5617bbce60')}}>{`5a)`} Shoulder press machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`5b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Lateral%20Raise.mp4?alt=media&token=51bc5c37-72b5-4845-9950-954c931034d9')}}>{`6)`} DB lateral raises 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`7)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRope%20Extensions.mp4?alt=media&token=4c44fc49-37ce-440a-b824-a6b5d7db7095')}}>{`7a)`} Rope extensions 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`7b)`}EZ cable extensions 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`1)`} Cable row machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`2a)`} Lat pulldown 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`3)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`4)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDante's%20Rows.mp4?alt=media&token=ae22ccc2-08d7-49c6-aabe-16df095556f7")}}>{`4a)`} Dante's rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`4b)`} Face pulls 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`5)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Hammer%20Curls.mp4?alt=media&token=2a121304-4282-4c23-b1b7-9aae60d1eeb1')}}>{`5a)`} DB hammer curls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`5b)`} DB curls 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`1)`} Hack squat machine 4 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FSplit%20Squats.mp4?alt=media&token=0278f009-c195-4c9f-8465-8468956701f6')}}>{`2)`} Split squats 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`3a)`} Leg extension 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHamstring%20Curl%20Machine.mp4?alt=media&token=ff02c611-f7a8-4e6f-b348-2e6b891a3c07')}}>{`5)`} Seated hamstring curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`5)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Flat%20Press.mp4?alt=media&token=e47cd565-3eac-47c1-99f4-f941a98dd473')}}>{`1)`} DB flat press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Incline%20Press.mp4?alt=media&token=4a1ef193-897e-4969-b4e4-e7328f2552f2')}}>{`2)`} DB incline press 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FIncline%20Press%20Machine.mp4?alt=media&token=06dc76dd-0bc6-4971-aadc-425ed402cae2')}}>{`3a)`} Incline press machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHigh%20to%20Low%20Cable%20Fly.mp4?alt=media&token=46ef15ec-9e30-4198-ab97-72ccfece6477')}}>{`4)`} High to low cable flys 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Lateral%20Raise.mp4?alt=media&token=c05d1b01-0506-491d-8fd4-7d6d5ad87a75')}}>{`5)`} Cable lateral raise 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLateral%20Raise%20Machine.mp4?alt=media&token=cf224dde-1760-4909-bbdb-359356498f5a')}}>{`6a)`} Lateral raise machine 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`6b)`} Drop set: 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=053c1d53-3291-48de-8c5b-dcd3abc482a5')}}>{`7)`} DB skull crushers 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`8a)`} EZ extensions 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`8b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLat%20Pulldown.mp4?alt=media&token=6298de69-9683-4952-8bb2-871385516dae')}}>{`1)`} Lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Rows.mp4?alt=media&token=45e97dbe-05be-4608-bd5c-437761e3b8bf')}}>{`2)`} DB rows 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCable%20Row%20Machine.mp4?alt=media&token=301de28f-5908-47bb-a3db-c719d9c05a47')}}>{`3a)`} Cable row machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`3b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=b347cdac-2083-48fc-b502-f1851b78be94')}}>{`4)`} Kneeling lat pulldown 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FFace%20Pulls.mp4?alt=media&token=1f5ae3f6-0e20-4a46-a8e0-077931a55a33')}}>{`5)`} Face pulls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`6)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20Curls.mp4?alt=media&token=98a2f0fb-4b40-4f04-8936-aac597bc6482')}}>{`6a)`} DB curls 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=6f7ebb56-2147-4ba6-920a-ebedb96a13ef')}}>{`6b)`} EZ inner grip curls 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FQuad%20Bias%20Leg%20Press.mp4?alt=media&token=3b9a2f29-0dca-4765-a601-0131562acc52')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHack%20Squat.mp4?alt=media&token=05dd1186-7b9a-4bb6-a1c0-cc5dc54638d4')}}>{`2)`} Hack squat machine 3 x 4-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`2b)`} Drop set: 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FLeg%20Extension.mp4?alt=media&token=16e13b45-9546-4470-8c5b-b11ed2b0d202')}}>{`3)`} Leg extension machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FHip%20Thrust.mp4?alt=media&token=44e69b0c-e309-4775-ad3e-03ef3ca0610a')}}>{`4)`} Barbell hip thrust / Hip thrust machine 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDrop%20Set.mp4?alt=media&token=3e9f7bac-65a6-48da-9e4b-08a287148997')}}>{`4b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FDB%20RDLs.mp4?alt=media&token=ac4167f4-6139-4135-b7a3-7204e6abc4ea')}}>{`5)`} DB RDLs 4 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FBicycle%20Crunches.mp4?alt=media&token=e6fc5d9a-03e2-4f11-b733-0c31c44640e4')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=cf5e8865-b0ba-4e63-a661-4af3e22a5ed3')}}>{`2)`} Knee to elbow crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=70ea9cd5-51fd-47db-920f-d472f4e8c1c7')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FRussian%20Twists.mp4?alt=media&token=03c3183d-7c1a-4a14-9527-c1c2b4863d2e')}}>{`4)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FToe%20Touches.mp4?alt=media&token=7d9a9bdc-8b15-4d9c-98f4-3bb5f1eb04d7')}}>{`5)`} Toe touches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  }else if (courseID==='00004'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`2)`} DB lateral raise 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`3)`} Cable lateral raise 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`4)`} Rope extensions 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`5)`} EZ cable extensions 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`3)`} Cable row machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`4)`} Face pulls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`5)`} DB curls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Hammer%20Curls.mp4?alt=media&token=79ec19c7-1967-49b6-9d8a-c900c7fed7c5')}}>{`6)`} DB hammer curls 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`2)`} Barbell hip thrust / Hip thrust machine 3 x 10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`3)`} DB RDLs 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`4)`} Seated hamstring curl machine 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFrog%20Kicks.mp4?alt=media&token=905cef9b-d581-4b81-a0b3-45342765e57d')}}>{`2)`} Frog kicks 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`3)`} Russian Twists 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raises 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`3)`} Lateral raise machine 3 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`4)`} DB lateral raises 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`5)`} Rope extensions 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6)`} EZ extensions 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`2)`} Cable row machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`3)`} Kneeling lat pulldown 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`4)`} DB rows 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=ebad7e30-03e7-40c5-8b83-1079602b6cc5')}}>{`6)`} EZ inside grip curls 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`7)`} EZ outside grip curls 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`2)`} Leg press 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`3)`} Split squats 3 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 3 x 6-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`1)`} Russian twists 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`2)`} Cross body mountain climbers 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 3 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 3 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`2)`} Shoulder press machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`3)`} DB lateral raises 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`4)`} Lateral raise machine 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`5)`} DB skullcrushers 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`6)`} EZ cable extensions 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`7)`} Rope extensions 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`1)`} Cable row machine 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`2)`} Lat pulldown 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`3)`} DB rows 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Hammer%20Curls.mp4?alt=media&token=79ec19c7-1967-49b6-9d8a-c900c7fed7c5')}}>{`6)`} DB hammer curls 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`7)`} DB curls 3 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`2)`} Split squats 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3)`} Barbell hip thrust / Hip thrust machine 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5)`} Seated hamstring curl 3 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raise 4 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`3)`} Cable lateral raise 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`4)`} Lateral raise machine 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`5)`} Rope extensions 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6)`} EZ extensions 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`7)`} DB skullcrushers 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`3)`} Cable row machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 4 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6)`} DB curls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`7)`} EZ outer grip curls 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`2)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3)`} Barbell hip thrust / Hip thrust machine 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5)`} Seated hamstring curls 4 x 8-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`2)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`4)`} Russian twists 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  }else if (courseID==='00005'){
    return(
      <div className='week-container'>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('1')}}>Week One</div>
          <div className='week-wrapper' id='week1'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('1')}}>Push Day</div>
            <div className='day-content' id='day1'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`2)`} Cable lateral raise 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`3)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`3a)`} DB lateral raise 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`3b)`} DB front raises 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`4)`} Rope extensions 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`5a)`} EZ cable extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('2')}}>Pull Day</div>
            <div className='day-content' id='day2'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`3a)`} Cable row machine 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,"https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDante's%20Rows.mp4?alt=media&token=21edc9e4-5559-43f9-adf3-3afd2af9caf8")}}>{`4)`} Dante's rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5a)`} Face pulls 2 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6)`} DB curls 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Hammer%20Curls.mp4?alt=media&token=79ec19c7-1967-49b6-9d8a-c900c7fed7c5')}}>{`7)`} DB hammer curls 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('3')}}>Leg Day</div>
            <div className='day-content' id='day3'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`2a)`} Barbell hip thrust / Hip thrust machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`3)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`4a)`} Seated hamstring curl machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`4b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('4')}}>Abs Circuit</div>
            <div className='day-content' id='day4'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFrog%20Kicks.mp4?alt=media&token=905cef9b-d581-4b81-a0b3-45342765e57d')}}>{`2)`} Frog kicks 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`3)`} Russian Twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`4)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('2')}}>Week Two</div>
          <div className='week-wrapper' id='week2'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('5')}}>Push Day</div>
            <div className='day-content' id='day5'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`1)`} Shoulder press machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raises 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`3a)`} Lateral raise machine 2 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`4)`} DB lateral raises 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`5)`} DB skullcrushers 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6a)`} EZ extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`6b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`7a)`} Rope extensions 2 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`7b)`} Drop set: 3 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('6')}}>Pull Day</div>
            <div className='day-content' id='day6'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`1)`} Lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`2a)`} Cable row machine 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`3)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`4)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5a)`} Face pulls 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`6)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=ebad7e30-03e7-40c5-8b83-1079602b6cc5')}}>{`6a)`} EZ inside grip curls 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`6b)`} EZ outside grip curls 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('7')}}>Leg Day</div>
            <div className='day-content' id='day7'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`2a)`} Leg press 2 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`3)`} Split squats 4 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5a)`} Seated hamstring curl machine 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('8')}}>Abs Circuit</div>
            <div className='day-content' id='day8'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`1)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`2)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFrog%20Kicks.mp4?alt=media&token=905cef9b-d581-4b81-a0b3-45342765e57d')}}>{`5)`} Frog Kicks 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('3')}}>Week Three</div>
          <div className='week-wrapper' id='week3'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('9')}}>Push Day</div>
            <div className='day-content' id='day9'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FShoulder%20Press%20Machine.mp4?alt=media&token=4a38262e-4a85-4cc3-be79-a8d586090134')}}>{`2a)`} Shoulder press machine 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Lateral%20Raise.mp4?alt=media&token=08023a73-2cef-4c8d-90e6-ef1960f1a92f')}}>{`3)`} DB lateral raises 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`4)`} Lateral raise machine 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`5)`} DB skullcrushers 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`6a)`} Rope extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`6b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00000%2FEZ%20Extensions.mp4?alt=media&token=1d8e619c-595c-47fa-817e-2a3868e89f42')}}>{`7a)`} EZ cable extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`7b)`} Drop set: 3 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('10')}}>Pull Day</div>
            <div className='day-content' id='day10'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`1)`} Cable row machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`2a)`} Lat pulldown 2 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`3)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5)`} Face pulls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`6)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Inside%20Grip%20Curls.mp4?alt=media&token=ebad7e30-03e7-40c5-8b83-1079602b6cc5')}}>{`6a)`} EZ inside grip curls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6b)`} DB curls 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('11')}}>Leg Day</div>
            <div className='day-content' id='day11'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`1)`} Hack squat machine 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FSplit%20Squats.mp4?alt=media&token=6e6a9792-77f9-455c-90d2-7c44070fd14d')}}>{`2)`} Split squats 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3a)`} Barbell hip thrust / Hip thrust machine 2 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5a)`} Seated hamstring curl 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('12')}}>Abs Circuit</div>
            <div className='day-content' id='day12'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`1)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`2)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FToe%20Touches.mp4?alt=media&token=cd6785e0-6960-4958-b211-0abb6edb80f4')}}>{`3)`} Toe touches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`4)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`5)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
        <div className='week-item'>
          <div className='week-title' onClick={()=>{toggleShowWeekContent('4')}}>Week Four</div>
          <div className='week-wrapper' id='week4'>
            <div className='day-title' onClick={()=>{toggleShowDayContent('13')}}>Push Day</div>
            <div className='day-content' id='day13'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Shoulder%20Press.mp4?alt=media&token=919e1ce5-394a-497e-a893-14346d2aab3a')}}>{`1)`} DB shoulder press 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Front%20Raise.mp4?alt=media&token=b9b61cc3-aba5-47e7-b484-f7432cba5389')}}>{`2)`} DB front raise 4 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Lateral%20Raise.mp4?alt=media&token=86795d4a-4380-48c8-90be-e1d0a59e059d')}}>{`3)`} Cable lateral raise 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLateral%20Raise%20Machine.mp4?alt=media&token=b43ada3d-1864-4405-9029-37511acb52fe')}}>{`4a)`} Lateral raise machine 2 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`4b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRope%20Extensions.mp4?alt=media&token=6af14384-34e7-4398-9989-d409d4bcc74a')}}>{`5a)`} Rope extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Extensions.mp4?alt=media&token=e2f18691-acf7-4518-bc23-9696d6c7e2b5')}}>{`6a)`} EZ extensions 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`6b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFlat%20DB%20Skullcrushers.mp4?alt=media&token=3a9ef45b-7269-442a-96fc-1c2d323e3c7e')}}>{`7)`} DB skullcrushers 4 x 10-12 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('14')}}>Pull Day</div>
            <div className='day-content' id='day14'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCable%20Row%20Machine.mp4?alt=media&token=46027cf5-a534-49bc-8e01-3ec5fcab3d09')}}>{`1)`} Cable row machine 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Rows.mp4?alt=media&token=185fa278-e506-4c10-a3f2-2b83f350d06f')}}>{`2)`} DB rows 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FLat%20Pulldown.mp4?alt=media&token=59621d98-3c58-4046-a026-4a5e827764df')}}>{`3a)`} Lat pulldown 2 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKneeling%20Lat%20Pulldown.mp4?alt=media&token=f7d919b0-23f1-4f98-85e4-897e6b0d8ce4')}}>{`4)`} Kneeling lat pulldown 4 x 10-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFace%20Pulls.mp4?alt=media&token=7ec5fc39-8014-42c9-bfeb-5e264ce9a2db')}}>{`5a)`} Face pulls 2 x 12-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'')}}>{`6)`} Superset the next two exercises</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20Curls.mp4?alt=media&token=18c50e0d-d9b4-40f2-a1c6-e89374fe5878')}}>{`6a)`} DB curls 4 x 10-15 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FEZ%20Outer%20Grip%20Curls.mp4?alt=media&token=bf792bcc-b653-43ed-b8e6-12e2d98bdc4d')}}>{`6b)`} EZ outer grip curls 4 x 10-15 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('15')}}>Leg Day</div>
            <div className='day-content' id='day15'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FGlute%20Bias%20Leg%20Press.mp4?alt=media&token=5b0e75a4-d7b4-4c2b-a843-5fea53f8b546')}}>{`1)`} Leg press 4 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHack%20Squat.mp4?alt=media&token=0ad87a41-8dd0-4716-9398-289d754619a4')}}>{`2a)`} Hack squat machine 2 x 6-8 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`2b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHip%20Thrust.mp4?alt=media&token=21b85805-dcfa-4861-861e-5ffce29a3138')}}>{`3a)`} Barbell hip thrust / Hip thrust machine 2 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`3b)`} Drop set: 3 x 6-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDB%20RDLs.mp4?alt=media&token=37fbb98e-749b-46a2-845f-dd4670248b54')}}>{`4)`} DB RDLs 4 x 8-10 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FHamstring%20Curl%20Machine.mp4?alt=media&token=3033655c-22bc-49ea-be1e-0c2e77c5dbbd')}}>{`5a)`} Seated hamstring curls 2 x 8-12 reps</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FDrop%20Set.mp4?alt=media&token=f17fa1aa-9c9f-41aa-8232-73508beb817b')}}>{`5b)`} Drop set: 3 x 8-10 reps</div>
            </div>
            <div className='day-title' onClick={()=>{toggleShowDayContent('16')}}>Abs Circuit</div>
            <div className='day-content' id='day16'>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FBicycle%20Crunches.mp4?alt=media&token=e4255ec8-4acc-44ef-87b1-016007252e64')}}>{`1)`} Bicycle crunches 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FKnee%20to%20Elbow%20Crunch.mp4?alt=media&token=0b3cba56-733d-437e-b805-c2bcd12df7eb')}}>{`2)`} Knee to elbow crunch 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FCross%20Body%20Mountain%20Climbers.mp4?alt=media&token=bbe87d6e-edd5-4d03-8fbe-1d9aaf906469')}}>{`3)`} Cross body mountain climbers 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FRussian%20Twists.mp4?alt=media&token=eaa89a31-acfc-4573-82a1-d463e85131f5')}}>{`4)`} Russian twists 4 x AMRAP</div>
              <div className='day-item' onClick={()=>{handleViewContent(setCurrentVideo,'https://firebasestorage.googleapis.com/v0/b/luccifit-91438.appspot.com/o/courses%2F00001%2FFrog%20Kicks.mp4?alt=media&token=905cef9b-d581-4b81-a0b3-45342765e57d')}}>{`5)`} Frog Kicks 4 x AMRAP</div>
              <div className='day-item'>AMRAP {'(As Many Reps As Possible)'}</div>
            </div>
            <div className='day-title'>Cardio 2x week your choice!</div>
          </div>
        </div>
      </div>
    )
  };
}
let handleViewContent = function(setCurrentVideo:any, videoSrc:any){
  setCurrentVideo(videoSrc);
  toggleCourseVideo();
};

let toggleShowWeekContent = function(weekContentNum:string){
  let weekContentDiv:any = document.querySelector('#week'+weekContentNum);
  //end function if the user presses on an already expanded drop down
  if (weekContentDiv.style.display==='flex'){
    weekContentDiv.style.display='none';
    return 0;
  }
  //minimize all days
  const allDays:any = document.querySelectorAll('.day-content');
  allDays.forEach((i:any)=>{
    i.style.display='none';
  });
  //hide all opened weeks
  const allWeeks:any = document.querySelectorAll('.week-wrapper');
  allWeeks.forEach((i:any)=>{
    i.style.display='none';
  });
  //show selected week
  if (weekContentDiv.style.display==='none'){
    weekContentDiv.style.display='flex';
  }else if (weekContentDiv.style.display==='flex'){
    weekContentDiv.style.display='none';
  }else{
    weekContentDiv.style.display='flex';
  }
};

let toggleShowDayContent = function(dayContentNum:string){
  let dayContentDiv:any = document.querySelector('#day'+dayContentNum);
  if (dayContentDiv.style.display==='flex'){
    dayContentDiv.style.display='none';
    return 0;
  }
  const allDays:any = document.querySelectorAll('.day-content');
  allDays.forEach((i:any)=>{
    i.style.display='none';
  });
  if (dayContentDiv.style.display==='none'){
    dayContentDiv.style.display='flex';
  }else if (dayContentDiv.style.display==='flex'){
    dayContentDiv.style.display='none';
  }else{
    dayContentDiv.style.display='flex';
  }
}
let toggleCourseVideo = function(){
  let videoDiv:any = document.querySelector('.current-course-video');
  if (videoDiv.style.display==='none'){
    videoDiv.style.display='flex';
  }else if (videoDiv.style.display==='flex'){
    videoDiv.style.display='none';
  }else{
    videoDiv.style.display='flex';
  }
};

let ownsCourse = function(courseID:any,setHasPerm:any){
  const user = getAuth(app).currentUser;
  //checks to see if user is not logged in
  if (!localStorage.getItem('userID')) setHasPerm(false);
  //checks to see if the user doesn't own the course
  if (user){
    getDocs(query(collection(database,'users'),where('uid','==',user.uid))).then((r:any)=>{
      r.forEach((i:any)=>{
        let courses: string[] = i.data().courses;
        if (!courses) setHasPerm(false);
        if (!courses.includes(courseID)){
          setHasPerm(false);
        };
      })
    })
  }
};