import React, {useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './home/Home';
import Register from './account/Register';
import UserPanel from './account/UserPanel';
import '../styles/app.css';
import Login from './account/Login';
import User from '../classes/User';
import CourseContent from './content/CourseContent';
import Checkout from './checkout/Checkout';
import CheckoutComplete from './CheckoutComplete';
import homeBack from '../assets/home-background.jpg';
import instagramLogo from '../assets/instagram-logo.svg';
import tiktokLogo from '../assets/tiktok-logo.svg';
import youtubeLogo from '../assets/youtube-logo.svg';
import mailLogo from '../assets/mail-logo.svg';
function App() {
  const [currentUser,setCurrentUser] = useState(new User([''],'','',''));
  return (
    <div className="app">
      <svg className='to-top-button' onClick={()=>{document.body.scrollTop = 0; document.documentElement.scrollTop = 0; }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16h2v-4.2l1.6 1.6L16 12l-4-4l-4 4l1.4 1.4l1.6-1.6V16Zm1 6q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"/></svg>
      <div className='home-background-wrapper'>
        <img src={homeBack} className='home-background' />
      </div>
      <div className='social-media-container'>
        <img src={instagramLogo} onClick={()=>{window.location.href='https://www.instagram.com/lucci.fit/'}} />
        <img src={tiktokLogo} onClick={()=>{window.location.href='https://www.tiktok.com/@lucci.fit'}} />
        <img src={youtubeLogo} onClick={()=>{window.location.href='https://www.youtube.com/@luccifit357'}} />
        <img src={mailLogo} onClick={()=>{window.location.href='mailto:info@luccifit.com'}} />
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} /> 
        <Route path='/user' element={<UserPanel currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
        <Route path='/course/:id/' element={<CourseContent currentUser={currentUser}/>} />
        <Route path='/checkout/:id/' element={<Checkout />} />
        <Route path='/checkout/success' element={<CheckoutComplete />} />
      </Routes>
    </div>
  );
}

export default App;